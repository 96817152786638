//------------------------------------------------------------------------------
import  { NgModule                                  } from '@angular/core'
import  { Imports, BaseModule                       } from './service'
//------------------------------------------------------------------------------
import  { OrdService                                } from './service'
import  { OrdLst                , Ord               } from './ordLst'
import  { OrdDtl                                    } from './ordDtl'
import  { OrdItmLst             , OrdItm            } from './ordItmLst'
//------------------------------------------------------------------------------
let c = [ OrdLst                , Ord
        , OrdDtl
        , OrdItmLst             , OrdItm
        ]
//------------------------------------------------------------------------------
@NgModule(
    { imports                   : Imports
    , declarations              : c
    , providers                 : [ OrdService ]
    }
)
export class OrdModule extends BaseModule {
    onConstructor() { this.glob.registerComponents(c) }
}
//------------------------------------------------------------------------------
