//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , RsrService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RsrLst', template:ListDiv})
export class RsrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:RsrService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6, isKey:true}
        , abbrev        : {lab:'short'              , wide: 2, isKey:true}
        , vnd_name      : {lab:'owner'              , wide: 8, isKey:true}
        , descr         : {lab:'description'        , wide: 8, isKey:true}
        , full_addr     : {lab:'vendor address'     , wide:20, isKey:true}
        , rsr_name_idl  : {lab:'receiving loc'      , wide: 6, isKey:true}

        , brand         : {lab:'brand'              , wide: 6}
        }
    //--------------------------------------------------------------------------
    groupingKey                 = 'id'
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.makeButton         (this.onNewP        , 'new location')
        this.makeButton         (this.onAddBrnP     , 'add brand'   )
        this.makeButton         (this.onRemBrnP     , 'remove brand')
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onAddBrnP() {
        let rsr_id
        return this.getSelectedIdsP()
        .then((rsr_ids) => {
            let ctx             =
                { tab           : 'brn'
                , selector      : ''
                , foundItems    : []
                , multi         : true
                }
            return this.callComponent('SelectCod', ctx)
            .then((res:any) => {
                if (!res)       { return RESOLVE() }
                if (is.object(res))
                                { res = [ res ] }
                return this.busyServiceEmitP('rsr_add_brns',
                    { rsr_ids   : rsr_ids
                    , brn_ids   : this.extractIds(res)
                    })
                .then(() => this.loadAndFocusP() )
            })
        })
    }
    //--------------------------------------------------------------------------
    onRemBrnP(key:string, values:any) {
        return this.getSelectedItemsP({ confirm:'removal' })
        .then((items) => this.busyServiceEmitP('rsr_remove_brns', { items:items }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Rsr', template:ItemDiv})
export class Rsr extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:RsrService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','typ']
        , name          : {lab:'name'               , wide: 4               , copyCheck:true}
        , abbrev        : {lab:'short name'         , wide: 4               , copyCheck:true}
        , vnd_id        : {opt:'owner'              , wide: 4, cod:'vnd'    }

        , descr         : {opt:'description'        , wide:12}

        , adr_id        : {opt:'vendor address'     , wide:12, cod:'adr_vnd'}

        , rsr_id_rcv    : {opt:'receiving location' , wide:12, cod:'rsr'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
