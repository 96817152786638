//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ChnDiv                , TabbedComp
        , ChnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ChnLst', template:ListDiv})
export class ChnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ChnService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'channel'            , wide: 5}
        , grouping      : {lab:'grouping'           , wide: 5}
        , src_name      : {lab:'source'             , wide: 5}
        , vnd_name      : {lab:'default owner'      , wide: 5}
        , rsr_names     : {lab:'warehouses'         , wide: 5, typ:'textarea'}
        , pgw_names     : {lab:'payment gateways'   , wide: 5, typ:'textarea'}
        , wrk_names_dlv : {lab:'delivery workflows' , wide: 5, typ:'textarea'}
        , wrk_names_vrt : {lab:'virtual workflows'  , wide: 5, typ:'textarea'}
        , wrk_names_rtn : {lab:'returns workflows'  , wide: 5, typ:'textarea'}
        , wrk_names_dis : {lab:'discont. workflows' , wide: 5, typ:'textarea'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer' }
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Chn', template:ChnDiv})
export class Chn extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:ChnService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.chn_id         =
        this.dft.chn_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
