//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , CpaTemplate           , TabbedComp
        , CpaService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CpaLst', template:ListDiv})
export class CpaLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CpaService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { vnd_name      : {lab:'vendor'             , wide: 5}
        , knt_name      : {lab:'supplier'           , wide: 5}
        , descr         : {lab:'description'        , wide:12}
        , per_name      : {lab:'period'             , wide: 2}
        , next_run_date : {lab:'next run'           , wide: 2, typ:'date'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft                =
            { vorgang           : this.ctx.vorgang
            , channel           : this.ctx.channel
            , vendor            : this.ctx.vendor
            , workflow          : this.ctx.workflow
            , status            : this.ctx.status
            }
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.makeButton         (this.onPaymentP    ,'create payment')
        this.buttonNew          (this.onNewP        )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNewCompName               = 'Cpa'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('Cpa', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return this.clone(this.ctx,
            { id:0, header:'new...' }
        )
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Cpa'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Cpa', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        return this.clone(this.ctx,
            { id:item.id, header:`${item.knt_name}: ${item.descr}` }
        )
    }
    //--------------------------------------------------------------------------
    onPaymentP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) =>
            this.busyServiceEmitP('cpa_create', { cpa_ids:ids })
        )
        .then(() => {
            this.messageDone('payment creation scheduled')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Cpa', template:CpaTemplate})
export class Cpa extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:CpaService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.cpa_id         =
        this.dft.cpa_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
