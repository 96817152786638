//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , MkrService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'MkrLst', template:ListDiv})
export class MkrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:MkrService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'marker'             , wide: 3}
        , typ_name      : {lab:'marker name'        , wide: 3}
        , tab           : {lab:'table'              , wide: 3}
        , tab_id        : {lab:'table id'           , wide: 3, typ:'integer'}
        , number        : {lab:'number'             , wide: 6}
        , name          : {lab:'name'               , wide: 6}
        , matchcode     : {lab:'matchcode'          , wide:12}
        , brand         : {lab:'brand'              , wide: 6}
        , groups        : {lab:'groups'             , wide:12}
        , stt_name      : {lab:'status'             , wide: 6}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    table_components    :any    =
        { 'art'         : { new:'SelectArt', update:'Art' }
        , 'prd'         : { new:'SelectPrd', update:'Prd' }
        }
    table_comp          :any    = null
    //--------------------------------------------------------------------------
    onInitP() {
        let listDefn            = this.clone(this.listDefn)
        if (this.ctx.marker && this.ctx.table in this.table_components) {
            this.dft.marker     = this.ctx.marker
            this.dft.table      = this.ctx.table
            let table   :string = this.ctx.table
            this.table_comp     = this.table_components[table]
            delete     listDefn.typ
            delete     listDefn.typ_name
            delete     listDefn.tab
            delete     listDefn.tab_id
            if (this.ctx.table == 'art') {
                delete listDefn.name
                delete listDefn.stt_name
            }
        } else {
            delete this.dft.marker
            delete this.dft.table
        }

        this.defineList         (listDefn           )
        this.setHeader          ('=menu='           )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonDelete       (this.onDeleteP     , 'clear selected')
        this.buttonNew          (this.onNewP        )

        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

this.log('CONTEXT:', this.ctx)

        return ( this.ctx.onReturn
               ? this.onReturnFromUpdate()
               : RESOLVE()
               )
        .then((update_result:any) =>
            this.loadAndFocusP()
            .then(() => {
                if (is.string(update_result) && update_result) {
                    this.messageText(update_result)
                }
                return RESOLVE()
            })
        )
    }
    //--------------------------------------------------------------------------
    onNewCompName               = ''
    onNewP() {
        if (!this.table_comp)   { return REJECT('unknown table') }

        let ctx                 =
            { selector          : ''
            , foundItems        : []
            , multi             : true
            }
        return this.callComponent(this.table_comp.new, ctx)
        .then((res:any) => {
            if (!res)           { return RESOLVE() }
            if (is.object(res)) { res = [ res ] }
            return this.busyServiceEmitP('add_flags',
                { marker        : this.dft.marker
                , table         : this.dft.table
                , tab_ids       : this.extractIds(res)
                })
            .then(() => this.loadAndFocusP() )
        })
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = ''
    onUpdateP(idx:number) {
        if (!this.table_comp)   { return REJECT('unknown table') }

        let item                = this.list[idx]
        let ctx                 = { id:item.tab_id }
        this.opts.ctx.onReturn  = item
        return this.callComponent(this.table_comp.update, ctx)
        .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    //--------------------------------------------------------------------------
    onReturnFromUpdate() {
// this.log('onReturnFromUpdate:', this.ctx.onReturn)
        return this.busyServiceEmitP('postTargetUpdate', this.ctx.onReturn)
        .catch((err:any) => {
// this.log('postTargetUpdate:', err)
            return REJECT(err)
        })
        .finally(() => {
            delete this.ctx.onReturn
        })
    }
    //--------------------------------------------------------------------------
    onDeleteP() {
        return this.getSelectedIdUpdsP({ confirm:'deletion' })
        .then((items) => {
            return this.busyServiceEmitP('dele_many', { items:items })
        })
        .then((res  ) => this.onDeleteDoneP(res) )
        .then((     ) => this.loadAndFocusP(   ) )
    }
    onDeleteDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
