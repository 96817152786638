//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdVrnLst', template:ListDiv})
export class PrdVrnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { suffix_x      : {lab:'sku suffix'         , wide: 2}
        , suffix        : {lab:'matchcode suffix'   , wide: 2}
        , ean_number    : {lab:'ean number'         , wide: 4}
        , price_adj     : {lab:'price adj.'         , wide: 2, typ:'decimal'}
        , ext_number    : {lab:'external sku'       , wide: 4}
        , weight        : {lab:'weight (kg)'        , wide: 2, typ:'quantity'}
        , sku_number    : {lab:'(linked sku number)', wide: 4}
//         , last_pur_date : {rdo:'last purchase date' , wide: 3, typ:'date'   }
        , pur_netto     : {opt:'purch. netto'       , wide: 2, typ:'decimal'}
        , is_base_vrn   : {lab:'is base variant'    , wide: 1, typ:'flag', cls:'clickable'}
        , sort_no       : {lab:'sort'               , wide: 2, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'vrn_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn, true)

        this.buttonNew          (this.onNewP        )
        this.makeButton         (this.onLinkArts    , 'link existing skus')
        this.makeButton         (this.onCreateSkus  , 'create skus')

        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.cellClick          (this.onSetBaseVrnP , 'is_base_vrn')

        this.listButton_cell    ('sku_number', 'add'   , this.whenAdd   , this.onAddP   , 'floatr')
        this.listButton_cell    ('sku_number', 'remove', this.whenRemove, this.onRemoveP, 'floatr')

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onLinkArts(key:string, values:any) {
        return this.busyServiceEmitP('link_art_variants', { prd_id: this.ctx.prd_id })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onCreateSkus(key:string, values:any) {
        return this.busyServiceEmitP('create_skus', { prd_id: this.ctx.prd_id })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onSetBaseVrnP(idx:number, key:string) {
        let item                = this.list[idx]
        return this.busyServiceEmitP('set_base_vrn',
            { prd_id            : this.ctx.prd_id
            , vrn_id            : this.list[idx].id
            }
        )
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    whenAdd(item:any) {
        return !item.sku_id
    }
    onAddP(idx:number) {
        let item                = this.list[idx]
        let ctx                 =
            { selector          : this.ctx.number || ''
            , foundItems        : []
            }
        return this.callComponent('SelectArt', ctx)
        .then((res:any) => {
            if (!res)           { return RESOLVE([]) }
            return this.busyServiceEmitP('vrn_add_art',
                { vrn_id        : item.id
                , sku_id        : res.id
                })
            .then(() => this.loadAndFocusP() )
        })
    }
    //--------------------------------------------------------------------------
    whenRemove(item:any) {
        return !!item.sku_id
    }
    onRemoveP(idx:number) {
        let item                = this.list[idx]
        return this.busyServiceEmitP('vrn_remove_art',
            { vrn_id            : item.id
            , sku_id            : item.sku_id
            })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PrdVrn', template:ItemDiv})
export class PrdVrn extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','sku_id']
        , suffix_x      : {opt:'sku suffix'         , wide: 3               , copyCheck:true}
        , suffix        : {opt:'matchcode suffix'   , wide: 3               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 3, typ:'integer'}

        , ean_number    : {opt:'ean number'         , wide: 6               , copyCheck:true}
        , price_adj     : {opt:'price adj. amount'  , wide: 3, typ:'decimal'}
        , is_base_vrn   : {lab:'is base variant'    , wide: 3, typ:'boolean'}

        , ext_number    : {opt:'external sku'       , wide: 3}
        , min_order_qty : {opt:'min. order qty'     , wide: 3, typ:'quantity'}
        , last_pur_date : {rdo:'last purchase date' , wide: 3, typ:'date'   }
        , pur_netto     : {opt:'purchase netto'     , wide: 3, typ:'decimal'}

        , height        : {opt:'height (cm)'        , wide: 3, typ:'integer'}
        , width         : {opt:'width (cm)'         , wide: 3, typ:'integer'}
        , depth         : {opt:'depth (cm)'         , wide: 3, typ:'integer'}
        , weight        : {opt:'weight (kg)'        , wide: 3, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'vrn_read'
    onSave_task                 = 'vrn_save'
    onDelete_task               = 'vrn_dele'
    //--------------------------------------------------------------------------
    onCopyP() {
        return this.setItemValuesP({ ean_number:'', sku_id:0 })
        .then(() => {
            this.disableField('pur_netto')
            return super.onCopyP()
        })
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        super.receivedItem(item)
        if (item.last_pur_date || !item.sku_id) {
            this.disableField('pur_netto')
        } else {
            this.enableField ('pur_netto')
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
