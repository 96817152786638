//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, EmpService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectEmp', template:SelectDiv})
export class SelectEmp extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:EmpService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { knt_name      : {lab:'employee'           , wide:10}
        , email         : {lab:'email'              , wide:10}
        , name          : {lab:'username'           , wide: 5}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select employees') }
        else                    { this.setHeader('select employee' ) }
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
