//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TxtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TxtLst', template:ListDiv})
export class TxtLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TxtService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { lng_name      : {lab:'language'           , wide: 3}
        , tab           : {lab:'table'              , wide: 3}
        , identifier    : {lab:'source'             , wide: 7}
        , typ_descr     : {lab:'description'        , wide: 5}
        , default_txt   : {lab:'default'            , wide:10, typ:'textarea'}
        , txt           : {lab:'localised'          , wide:10, typ:'textarea'}
        , missing       : {lab:'n/a'                , wide: 1, typ:'flag'}
//         , stale         : {lab:'stale'              , wide: 1, typ:'flag'}
        , key           : {lab:'key'                , wide: 3}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        let listDefn            = this.clone(this.listDefn)
        let setup               = (key, alias) => {
            let value           = this.ctx[key] !== undefined
                                    ? this.ctx[key  ]
                                    : this.ctx[alias]
            if (value === undefined)
                                { return }
            this.dft[key]       = value
            delete listDefn[key]
        }
        this.dft                = {}
        setup('lng_name'        , 'language'            )
        setup('tab'             , 'table'               )
        setup('identifier'      , 'source'              )
        setup('typ_descr'       , 'description'         )
        setup('missing'         , 'missing'             )
//         setup('stale'           , 'stale'               )
        setup('key'             , 'key'                 )

        setup('brand'           , 'brand'               )
        setup('prd_stt_typ'     , 'product_status_type' )

        this.defineList         (listDefn           )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        return { id             : item.id
               , tab            : item.tab
               , tab_id         : item.tab_id
               , typ_id         : item.typ_id
               , lng_id         : item.lng_id
               , key            : item.key
               }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Txt', template:ItemDiv})
export class Txt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TxtService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','tab','tab_id','typ_id','lng_id','key']
        , lng_name      : {rdo:'language'           , wide:12}
        , identifier    : {rdo:'identifier'         , wide:12}
        , default_txt   : {rdo:'default'            , wide:11, typ:'textarea'}
        , default_txt_copy
                        : {lab:'copy'               , wide: 1, btn:this.onCopy}
        , txt           : {opt:'localised'          , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.id) {
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        } else {
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        }

        return this.readP(this.ctx)
        .then(() => {
this.log('ITEM:', this.item)
            if (this.item.tab == 'prd') {
                this.enableField('default_txt')
            }
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onCopy(key:string, value:any) {
        this.copyToCB(key.replace(/_copy$/, ''))
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
