//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , WrgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'WrgLst', template:ListDiv})
export class WrgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:WrgService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide:10}
        , grouping      : {lab:'grouping'           , wide:10}
        , rbg_name      : {lab:'discount group'     , wide:10}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Wrg', template:ItemDiv})
export class Wrg extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:WrgService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide:12               , copyCheck:true}

        , grouping      : {opt:'grouping'           , wide:12}

        , rbg_id        : {opt:'discount group'     , wide:12, cod:'rbg'    , copyCheck:true}

        , sort_no       : {opt:'sort'               , wide:12, typ:'integer'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
