//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , RolTemplate           , TabbedComp
        , RolService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RolLst', template:ListDiv})
export class RolLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:RolService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'role'               , wide: 5}
        , descr         : {lab:'description'        , wide: 5}
        , scp_paths     : {lab:'menu items'         , wide: 5, typ:'textarea'}
        , emp_names     : {lab:'usernames'          , wide:10}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer' }
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onNewCompName               = 'Rol'
    onNewContext() {
        return { }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Rol'
    onUpdateContext(idx:number) {
        return { rol_id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Rol', template:RolTemplate})
export class Rol extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:RolService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.rol_id         =
        this.dft.rol_id         = this.ctx.rol_id// || this.dft.rol_id
        this.service.setHeader(null)
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
