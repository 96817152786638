//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , UsrService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'UsrLst', template:ListDiv})
export class UsrLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:UsrService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { email         : {lab:'email'              , wide:10}
        , name          : {lab:'name'               , wide:10}
        , insert_date   : {lab:'created'            , wide: 5, typ:'datetime'}
        , verified      : {lab:'verified'           , wide: 1, typ:'flag'}
        , knt_name      : {lab:'account name'       , wide:10}
        , knt_email     : {lab:'account email'      , wide:10}
        , mismatch      : {lab:'mismatch'           , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.emptyCriteria) {
            this.criteria.email = 'burgschneider|ecus|liveactionarts'
        }
        return super.onInitP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Usr', template:ItemDiv})
export class Usr extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:UsrService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , email         : {opt:'email'              , wide:12}

        , name          : {lab:'name'               , wide:12}

        , new_password  : {opt:'new password'       , wide:12}

        , knt_id        : {opt:'account id (if you know what you are doing)'
                                                    , wide:12, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        let def                 = this.itemDefn
        if (this.ctx.id) {
            this.defineItem     (def                )
            this.setHeader      ('edit'             )
            this.buttonCancel   (this.onCancelP     )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        } else {
            this.defineItem     (def                )
            this.setHeader      ('add'              )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        }

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
