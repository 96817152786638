//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TxtSupService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TxtSupLst', template:ListDiv})
export class TxtSupLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TxtSupService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { tab_name      : {lab:'vendor'             , wide: 5}
        , typ_name      : {lab:'name'               , wide: 5}
        , typ_descr     : {lab:'description'        , wide: 5}
        , txt           : {lab:'default value'      , wide:16}
        , typ_key       : {lab:'key'                , wide: 5}
        , missing       : {lab:'n/a'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateContext(idx:number) {
// this.log('onUpdateContext:', idx)
        let item                = this.list[idx]
        return { header         : item.tab_name
               , id             : item.id
               , tab            : item.tab
               , tab_id         : item.tab_id
               , typ_id         : item.typ_id
               }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'TxtSup', template:ItemDiv})
export class TxtSup extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TxtSupService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','tab','tab_id','typ_id']
        , typ_descr     : {rdo:'context'            , wide:12}
        , txt           : {opt:'text'               , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        let args
        if (this.ctx.id) {
            this.setHeader      (this.ctx.header    )
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonDelete   (this.onDeleteP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = { id              : this.ctx.id }
        } else {
            this.setHeader      (this.ctx.header    )
            this.defineItem     (this.itemDefn      )
            this.buttonCancel   (this.onCancelP     )
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
            args                = { tab             : this.ctx.tab
                                  , tab_id          : this.ctx.tab_id
                                  , typ_id          : this.ctx.typ_id
                                  }
        }

        return this.readP(args)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
