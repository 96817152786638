//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TacService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TacLst', template:ListDiv})
export class TacLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TacService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ_typ       : {lab:'type'               , wide: 2}
        , version       : {lab:'version'            , wide: 1, typ:'integer'}
        , text          : {lab:'text'               , wide:50, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Tac', template:ItemDiv})
export class Tac extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TacService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ_id        : {lab:'type'               , wide:10, cod:'tac'}
        , version       : {rdo:'version'            , wide: 2, typ:'integer'}
        , text          : {lab:'text'               , wide:12, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
