//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, ArtService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectArt', template:SelectDiv})
export class SelectArt extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { number        : {lab:'number'             , wide: 3}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , ean_number    : {lab:'ean number'         , wide: 3}
        , ext_number    : {lab:'external sku'       , wide: 3}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader('select article')
        this.onInitSelect()

        return this.loadWithP(this.ctx.foundItems)
    }
    //--------------------------------------------------------------------------
    onSearchP(key:string, values:any) {
        return this.busyServiceEmitP('search', values)
        .then((res) => res.foundItems
            ? this.loadWithP(res.foundItems)
            : this.onReturnResP(res)
        )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
