//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PriDefService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PriDefLst', template:ListDiv})
export class PriDefLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PriDefService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { cur_name      : {lab:'currency'           , wide: 2}
        , pri_class     : {lab:'price class'        , wide: 2}
        , shp_class     : {lab:'shipping class'     , wide: 2}
        , percent       : {lab:'percent'            , wide: 1, typ:'percent'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return this.ctx
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'PriDef', template:ItemDiv})
export class PriDef extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PriDefService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , cur_id        : {lab:'currency'           , wide:12, cod:'cur'    , copyCheck:true}
        , pri_cls_id    : {lab:'price class'        , wide:12, cod:'pri_cls', copyCheck:true}
        , shp_cls_id    : {lab:'shipping class'     , wide:12, cod:'shp_cls', copyCheck:true}
        , percent       : {lab:'percent'            , wide:12, typ:'percent'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
