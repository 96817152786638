//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, CatService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectCat', template:SelectDiv})
export class SelectCat extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:CatService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { value         : {opt:'category path'      , wide:12}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select categories') }
        else                    { this.setHeader('select category'  ) }
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
