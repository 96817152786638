//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , TktService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TktSal', template:ListDiv})
export class TktSal extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TktService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { startdate     : {lab:'date'               , wide: 2, typ:'dates'  }
        , available     : {lab:'avail'              , wide: 1, typ:'flag'   }
        , number        : {lab:'article'            , wide: 2}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , brutto        : {lab:'price'              , wide: 1, typ:'decimal'}
        , max_qty       : {lab:'limit'              , wide: 1, typ:'integer'}
        , sold_qty      : {lab:'ordered'            , wide: 1, typ:'intnone', tot:true}
//         , remaining     : {lab:'remain'             , wide: 1, typ:'intnone', tot:true}
        , checkout      : {lab:'checkout'           , wide: 1, typ:'intnone', tot:true}
        , issued        : {lab:'issued'             , wide: 1, typ:'intnone', tot:true}
//         , redeemed      : {lab:'redeem'             , wide: 1, typ:'intnone', tot:true}
        , day_1         : {lab:'today'              , wide: 1, typ:'intnone', tot:true}
        , day_2         : {lab:'1 day'              , wide: 1, typ:'intnone', tot:true}
        , day_3         : {lab:'2 day'              , wide: 1, typ:'intnone', tot:true}
        , day_4         : {lab:'3 day'              , wide: 1, typ:'intnone', tot:true}
        , day_5         : {lab:'4 day'              , wide: 1, typ:'intnone', tot:true}
        , day_6         : {lab:'5 day'              , wide: 1, typ:'intnone', tot:true}
        , day_7         : {lab:'6 day'              , wide: 1, typ:'intnone', tot:true}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'sales'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        let since               = this.ctx.since    ? this.ctx.since
                                                    : 'week'
        return Object.assign({ date0:this.getDateSince(since) }, this.ctx)
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
        this.set_cell_bg(idx, 'sold_qty', item.sold_qty >= item.max_qty ? 'green' :'none')
        this.set_cell_bg(idx, 'reserved', item.reserved                 ? 'orange':'none')
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
