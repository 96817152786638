//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, delayTimer
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv               , ItemModal
        , ListDiv               , ListComp
        , SelectDiv             , SelectModal
        , RulService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RulLst', template:ListDiv})
export class RulLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:RulService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { wrk_name      : {lab:'workflow'           , wide: 5, isKey:true}
        , stt_name      : {lab:'state'              , wide: 3, isKey:true}
        , evn_name      : {lab:'event'              , wide: 3, isKey:true}
        , stt_name_next : {lab:'next state'         , wide: 3, isKey:true}

        , opt_sort      : {rdo:'sort'               , wide: 1, typ:'intnone', cls:'left-border'}
        , opt_type      : {lab:'option'             , wide: 3}
        , opt_data      : {lab:'detail'             , wide:10}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'load'
    groupingKey                 = 'id'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.makeButton         (this.onNewP        , 'new rule'    )
        this.makeButton         (this.onNewOptP     , 'new option'  )
        this.makeButton         (this.onRenumberP   , 'renumber'    )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.cellDclick         (this.onUpdOptP     , 'opt_sort')
        this.cellDclick         (this.onUpdOptP     , 'opt_type')
        this.cellDclick         (this.onUpdOptP     , 'opt_data')
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNewOptP() {
        let rul_id, opt_id
        return this.getSelectedItemP()
        .then((res) => {
// this.log('onNewOptP.getSelectedItemP:', res)
            rul_id              = res.id
            return this.callComponent('SelectRulOpt', {})
        })
        .then((res) => {
// this.log('onNewOptP.SelectRulOpt result:', res)
            if (!res)           { return REJECT() }
            opt_id              = res.key
// KLUDGE: we have to wait some time for the close event triggered by the
//         SelectRulOpt modal to finish firing otherwise it will also close the
//         next modal before we get a chance to use it :-(
            return delayTimer(1000)
        })
        .then(() => {
// this.log('onNewOptP.setTimeout')
            return this.callComponent('RulOpt', { rul_id:rul_id, typ_id:opt_id })
        })
        .then((res:any) => {
// this.log('onNewOptP.RulOpt result:', res)
            return this.onNewDoneP(res)
        })
    }
    //--------------------------------------------------------------------------
    onUpdOptP(idx:number) {
// this.log(`onUpdOptP(${idx})`)
        let opt_id              = this.list[idx].opt_id
        if (!opt_id)            { return this.onUpdateP(idx) }
        return this.callComponent('RulOpt', { opt_id:opt_id })
        .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    //--------------------------------------------------------------------------
    onRenumberP() {
        return this.getSelectedIdsP()
        .then((res) => {
            // remove duplicates...
            res                 = [ ...new Set(res) ]
this.log('onRenumberP.getSelectedIdsP:', res)
            return this.busyServiceEmitP('renumber', { rul_ids:res })
        })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Rul', template:ItemDiv})
export class Rul extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:RulService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , wrk_id        : {lab:'workflow'           , wide:12, cod:'wrk'    , copyCheck:true}
        , stt_id        : {lab:'state'              , wide:12, cod:'stt'    , copyCheck:true}
        , evn_id        : {lab:'event'              , wide:12, cod:'evn'    , copyCheck:true}
        , stt_id_next   : {lab:'next state'         , wide:12, cod:'stt'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'SelectRulOpt', template:SelectDiv})
export class SelectRulOpt extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:RulService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { value         : {lab:'name'               , wide:12}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader('select rule option')
        this.onInitSelect()

        return this.onSearchP('init', {})
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onSearchP(key:string, values:any) {
        return this.busyServiceEmitP('load_rul_opts', values)
        .then((res) => res.foundItems
            ? this.loadWithP(res.foundItems)
            : this.onReturnResP(res)
        )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'RulOpt', template:ItemDiv})
export class RulOpt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:RulService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    onInitP() {
// this.log('onInitP:', this.ctx)
        return this.readP(this.ctx)
        .then(() => {
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onRead_task                 = 'opt_read'
    onSave_task                 = 'opt_save'
    onDelete_task               = 'opt_dele'
    //--------------------------------------------------------------------------
    readResult(res:any) {
// this.log('readResult:', res)
        this.itemDefn           = res.itemDefn
        this.defineItem(this.itemDefn)
        this.setHeader          (res.item.typ_name  )
        this.buttonCancel       (this.onCancelP     )
        if (res.item.id) {
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

// this.log('readResult item:', res.item)
        return res.item
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
