//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, jQuery
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ArtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtDimLst', template:ListDiv})
export class ArtDimLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { shelves       : {lab:'shelves'            , wide: 3, typ:'textarea'}
        , number        : {lab:'number'             , wide: 4}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , ean_number    : {lab:'ean number'         , wide: 3}
        , height        : {lab:'height (cm)'        , wide: 2, typ:'integer'}
        , width         : {lab:'width (cm)'         , wide: 2, typ:'integer'}
        , depth         : {lab:'depth (cm)'         , wide: 2, typ:'integer'}
        , weight        : {lab:'weight (kg)'        , wide: 2, typ:'quantity'}
        , abc_typ       : {lab:'qty ABC'            , wide: 1, cls:'center'}
        , status        : {lab:'status'             , wide: 3}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'dim_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader      ('=menu='           )
        this.defineList     (this.listDefn      )
        this.onCriteria     (this.onCriteriaP   )
        this.listDclick     (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'ArtDimUpd'
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'ArtDimUpd', template:ItemDiv})
export class ArtDimUpd extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , number        : {rdo:'number'             , wide: 3, newline:true}
        , matchcode     : {rdo:'matchcode'          , wide: 9}

        , height        : {opt:'height (cm)'        , wide: 3, typ:'integer'
                                                             , rtn:this.onHeight}
        , width         : {opt:'width (cm)'         , wide: 3, typ:'integer'
                                                             , rtn:this.onWidth}
        , depth         : {opt:'depth (cm)'         , wide: 3, typ:'integer'
                                                             , rtn:this.onDepth}

        , weight        : {opt:'weight (kg)'        , wide: 3, typ:'quantity'
                                                             , newline:true
                                                             , rtn:this.onWeight}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'dim_read'
    onSave_task                 = 'dim_save'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.buttonOk           (this.onSubmitP     )
//         this.formSubmit         (this.onSubmitP     )

        return this.readP(this.ctx)
        .then ((res) => this.setFocusP('height') )
    }
    //--------------------------------------------------------------------------
    onHeight(key:string, value:any) {
this.log('onHeight:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        return this.setFocusP('width')
    }
    //--------------------------------------------------------------------------
    onWidth(key:string, value:any) {
this.log('onWidth:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        return this.setFocusP('depth')
    }
    //--------------------------------------------------------------------------
    onDepth(key:string, value:any) {
this.log('onDepth:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        return this.setFocusP('weight')
    }
    //--------------------------------------------------------------------------
    onWeight(key:string, value:any) {
this.log('onWeight:', { key, value })
        if (is.empty(value)) {
            this.messageError(`no ${key} entered`)
            return this.setFocusP(key)
        }
        this.item[key]              = value
        jQuery('input.submit').click()
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
//     onSubmitP(key:string, values:any) {
// this.log('onSubmitP', { key, values })
//         if (!values)            { return RESOLVE() }
//         return this.busyServiceEmitP(this.onSave_task, values)
//         .then((res:any) => this.onSubmitDoneP(res) )
//         .catch((err) => {
//             this.messageError(err)
//             return RESOLVE()
//         })
//     }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
