//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, CodService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectCod', template:SelectDiv})
export class SelectCod extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:CodService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 6}
        , name          : {lab:'name'               , wide:12}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select items') }
        else                    { this.setHeader('select item' ) }

        this.dft.tab            = this.ctx.tab
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
