//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, regexTest
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , OrdTemplate           , TabbedComp
        , OrdService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OrdLst', template:ListDiv})
export class OrdLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OrdService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
        , ols_name      : {lab:'shop'               , wide: 4}
        , number        : {lab:'number'             , wide: 4}
        , email         : {lab:'email'              , wide: 6}
        , usr_name      : {lab:'user name'          , wide: 6}
        , fullname      : {lab:'fullname'           , wide: 6}
        , lnd_name      : {lab:'country'            , wide: 4}
        , brutto        : {lab:'brutto'             , wide: 3, typ:'decimal', tot:true}
        , cur_typ       : {lab:'cur'                , wide: 2}
        , pay_typ       : {lab:'pay'                , wide: 2}
        , status        : {lab:'status'             , wide: 2}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        let onNewFunction       = null
        if (this.ctx.canCreate) {
            try {
                this.ctx.newOpts.vorgang
                                = this.ctx.newOpts.vorgang.toLowerCase()
                this.ctx.newOpts.typ
                                = this.ctx.newOpts.vorgang
                this.ctx.newOpts.rsr_id
                                = this.ctx.rsr_id
                onNewFunction   = this.onNewP
            } catch(err) {
                delete this.ctx.newOpts
            }
        }
//TEST        this.dft                = this.ctx
        this.dft                =
            { ols_id            : this.ctx.ols_id
            , vorgang           : this.ctx.vorgang
            , channel           : this.ctx.channel
            , vendor            : this.ctx.vendor
            , workflow          : this.ctx.workflow
            , location          : this.ctx.location
            , rsr_id            : this.ctx.rsr_id
            , status            : this.ctx.status
            }

//         let listDefn            = Object.assign({}, this.listDefn)
        let listDefn            = { ...this.listDefn }
        if (this.ctx.referrer !== true) {
            // suppress referrer...
            delete listDefn.rfr_name
        }

        if (this.ctx.include_EUR !== true) {
            // suppress acc_netto/brutto...
            delete listDefn.acc_netto
            delete listDefn.acc_brutto
        }

        if (this.ctx.vorgang) {
            let typs            = is.array(this.ctx.vorgang)
                                    ?   this.ctx.vorgang
                                    : [ this.ctx.vorgang ]
            let allow_rsr       = typs.some((typ) =>
                                    regexTest(typ, 'dlv', 'ior', 'idl')
                                  )
            if (!allow_rsr) {
                // suppress location...
                delete listDefn.rsr_name
            }
        }

        this.defineList         (listDefn           )

        this.setHeader          ('=menu='           )
        this.buttonNew          (onNewFunction      )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonPrint        (this.onPrintP      )
        if (regexTest(this.ctx.vorgang, 'inv') && this.ctx.canBookInvoices) {
            this.makeButton     (this.onAccCreateP  , 'book'    )
        }
        if (regexTest(this.ctx.vorgang, 'acc') && this.ctx.canBookInvoices) {
            this.makeButton     (this.onAccDownloadP, 'download')
        }
        if (regexTest(this.ctx.vorgang, 'cpa', 'ior')) {
            this.makeButton     (this.onGetDatafileP, 'download')
        }
        this.eventButtons       (this.ctx.events    )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        let since               = this.ctx.since    ? this.ctx.since
                                : this.ctx.status   ? 'month'
                                : this.ctx.vorgang  ? 'week'
                                                    : 'day'
        return Object.assign({ date0:this.getDateSince(since) }, this.ctx)
    }
    //--------------------------------------------------------------------------
    onNewCompName               = ''
    onNewP() {
        this.onNewCompName      = this.ctx.newPage
        return super.onNewP()
//         let ctx                 = this.onNewContext()
//         return this.callComponent(this.ctx.newPage, ctx)
//         .then((res:any) => this.onNewDoneP(res) )
    }
    onNewContext() {
        return this.clone(this.ctx, this.ctx.newOpts,
            { header            : 'new...'
            , id                : 0
            , canEdit           : true
            }
        )
    }
    //--------------------------------------------------------------------------
    onUpdateCompName           = ''
    onUpdateP(idx:number) {
        this.onUpdateCompName   = this.ctx.editPage
        return super.onUpdateP(idx)
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent(this.ctx.editPage, ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        let header              = (!item.knt_name)  ? item.number
                                                    : `${item.number}: ${item.knt_name}`
        let canEdit
        if ( regexTest(item.typ    , 'acc', 'inv')
          || regexTest(item.stt_typ, 'cmp', 'inv')
           ) {
            canEdit             = false
        } else {
            canEdit             = this.ctx.canEdit
        }
        return this.clone(this.ctx,
            { header            : header
            , id                : item.id
            , typ               : item.typ
            , canEdit           : canEdit
            }
        )
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            return this.busyServiceEmitP('event',
                { event         : key
                , ord_ids       : ids
                })
        })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            this.messageText('print request submitted')
            return this.downloadP(
                { ord_ids           : ids
                , TIMEOUT           : ids.length * 20
                },
                { name              : 'print'
                , task              : 'print'
                })
        })
    }
    //--------------------------------------------------------------------------
    onAccCreateP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            this.messageText('booking selected invoices...')
            return this.serviceEmitP('acc_create', { ord_ids:ids })
        })
        .then((ids:any) => this.accDownloadP(ids) )
    }
    //--------------------------------------------------------------------------
    onAccDownloadP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => this.accDownloadP(ids) )
    }
    //--------------------------------------------------------------------------
    accDownloadP(ids:any) {
        this.messageText('accounting download request submitted')
        return this.downloadP({ acc_ids:ids },
            { name              : 'accounting download'
            , task              : 'acc_download'
            })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onGetDatafileP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((items:any) =>
            this.downloadP({ filenames:items.map((item:any) => item.number + '.xlsx') },
                { name          : 'details'
                , task          : 'get_datafile'
                })
        )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Ord', template:OrdTemplate})
export class Ord extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:OrdService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
this.log('CTX:', this.ctx)
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.ord_id         =
        this.dft.ord_id         = this.ctx.id
        this.dft.ord_typ        = this.ctx.typ
        this.dft.ols_id         = this.ctx.ols_id
        this.dft.newOpts        = this.ctx.newOpts

        // this is required otherwise a "save" tries to use the workflow...
        delete this.dft.workflow

this.log('DFT:', this.dft)
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        if (!this.ctx.ord_id) {
            return REJECT('no document selected')
        }
        this.messageText('print request submitted')
        return this.downloadP(
            { ord_ids           : [ this.ctx.ord_id ]
            },
            { name              : 'print'
            , task              : 'print'
            })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
