//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , TaxLnkService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TaxLnkLst', template:ListDiv})
export class TaxLnkLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TaxLnkService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { vnd_name      : {lab:'vendor'             , wide: 6}
        , lnd_name      : {lab:'country'            , wide: 6}
        , atx_name      : {lab:'tax (article)'      , wide: 4}
        , tax_name      : {lab:'tax'                , wide: 8}
        , tvi_name      : {lab:'tax (with VAT id)'  , wide: 8}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'TaxLnk', template:ItemDiv})
export class TaxLnk extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:TaxLnkService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , vnd_id        : {lab:'vendor'             , wide: 6, cod:'vnd'    , copyCheck:true}
        , lnd_id        : {lab:'country'            , wide: 6, cod:'lnd_all', copyCheck:true}

        , art_tax_id    : {lab:'tax (article)'      , wide: 6, cod:'art_tax', copyCheck:true}

        , tax_id        : {lab:'tax'                , wide: 6, cod:'tax'    , newline:true}
        , tax_id_vatid  : {lab:'tax (with VAT id)'  , wide: 6, cod:'tax'    }
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
