//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , EntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EntLst', template:ListDiv})
export class EntLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:EntService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 4}
        , name          : {lab:'name'               , wide: 6}
        , uniq          : {lab:'unique'             , wide: 1, typ:'flag'   }
        , descr         : {lab:'description'        , wide:12}
        , is_public     : {lab:'public'             , wide: 1, typ:'flag'   }
        , mmb_name      : {lab:'membership'         , wide: 2}
        , tab           : {lab:'table'              , wide: 2}
        , tab_id        : {lab:'id'                 , wide: 1, typ:'integer'}
        , tab_descr     : {lab:'table description'  , wide:12}
        , cnt_count     : {lab:''                   , wide: 3, typ:'bare'}
        , att_count     : {lab:''                   , wide: 3, typ:'bare'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.ent_id_to      = this.ctx.ent_id_to || 0
        this.defineList         (this.listDefn      )
        this.setHeader          (this.ctx.header || '=menu=')
        this.buttonBack         (this.onBackP       )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )
        this.listButton_cell    ('cnt_count', 'cont', () => true, this.onOpenP, 'floatr')
        this.listButton_cell    ('att_count', 'attr', () => true, this.onAttrP, 'floatr')

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        return { id:item.id, typ:item.typ }
    }
    //--------------------------------------------------------------------------
    onOpenP(idx:number) {
        let item                = this.list[idx]
        let header              = this.ctx.header || this.glob.menu.title
        let ctx                 = { header          : `${header} / ${item.name}`
                                  , ent_id_to       : item.id
                                  , typ             : item.typ
                                  }
        return this.callComponent('EntLst', ctx)
        .then((res:any) => this.onOpenDoneP(res) )
    }
    onOpenDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onAttrP(idx:number) {
        let item                = this.list[idx]
        let header              = this.ctx.header || this.glob.menu.title
        let ctx                 = { header          : `${header} / ${item.name}`
                                  , ent_id          : item.id
                                  , typ             : item.typ
                                  }
        return this.callComponent('EntAttLst', ctx)
        .then((res:any) => this.onAttrDoneP(res) )
    }
    onAttrDoneP(res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Ent', template:ItemDiv})
export class Ent extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:EntService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    baseDefn            :any    =
        { HIDE          : ['id','update_date','ent_id_to']
        , ent_name_to   : {rdo:'parent'             , wide:12}
        , typ           : {lab:'type'               , wide:12               , copyCheck:true}
        , name          : {opt:'name'               , wide: 6               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 3, typ:'integer'}
        , uniq          : {lab:'is unique'          , wide: 3, typ:'boolean', copyCheck:true}
        , descr         : {opt:'description'        , wide:12}
        , is_public     : {lab:'is public'          , wide: 6, typ:'boolean'}
        , mmb_typ       : {lab:'membership'         , wide: 6, cod:'mmb'    }
        , tab           : {opt:'database table name', wide: 6}
        , tab_id        : {opt:'database table id'  , wide: 6, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        if (is.empty(this.ctx.typ))
                                { return this.onInitResP() }
        return this.busyServiceEmitP('initRead', this.ctx)
        .then((res:any) => this.onInitResP(res) )
    }
    onInitResP(res:any={}) {
        this.itemDefn           = this.clone(this.baseDefn)
        for (let key in (res.itemDefn || {})) {
            this.itemDefn[key]  = res.itemDefn[key]
        }

//         this.copyCheckKeys      = res.copyCheckKeys || []
        this.setHeader          (res.header     || '=menu=' )
        this.defineItem         (this.itemDefn)

        if (this.ctx.id) {
//             this.buttonPrint    (this.onPrintP      )
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
