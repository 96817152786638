//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, EmpService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'EmpDtl', template:ItemDiv})
export class EmpDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:EmpService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']

        , name          : {lab:'username'           , wide: 6, newline:true}
        , knt_id        : {opt:'employee account'   , wide: 6, cod:'knt_emp'}

        , email         : {opt:'email'              , wide: 6, newline:true}
        , new_password  : {opt:'new password'       , wide: 6}

        , filters       : {opt:'filters'            , wide:12, newline:true}

        , password      : {rdo:'password hash'      , wide: 6, newline:true}
        , auth_hash     : {rdo:'2FA hash'           , wide: 6}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        if (this.ctx.id) {
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.ctx.id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        this.ctx.id             =
        this.ctx.emp_id         =
        this.dft.emp_id         = this.item.id
        this.setCache(this.item)
        this.service.setHeader(this.item)
        if (this.item.id) {
            this.service.showValidTabs()
            this.showButton('copy'  )
            this.showButton('delete')
        } else {
            this.hideButton('copy'  )
            this.hideButton('delete')
        }
//         if (this.item.last_pur_date) {
//             this.disableField('pur_netto')
//         } else {
//             this.enableField ('pur_netto')
//         }
    }
    //--------------------------------------------------------------------------
    onCopyP() {
        return this.setItemValuesP({ id:0 })
        .then(() => {
            this.ctx.id         =
            this.ctx.emp_id     =
            this.dft.emp_id     = this.item.id
            this.setCache(this.item)
            this.service.setHeader(this.item)
            this.service.initTabs(this.item.id, 'dtl')
            this.hideButton('copy'  )
            this.hideButton('delete')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        return this.onBackP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
