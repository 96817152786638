//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PtnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PtnLst', template:ListDiv})
export class PtnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PtnService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ_name      : {lab:'type'               , wide: 3}
        , tier_txt      : {lab:'tier'               , wide: 3}
        , descr         : {lab:'description'        , wide: 6}
        , comm_pct      : {lab:'commission'         , wide: 2, typ:'percent'}
        , annual_allowance
                        : {lab:'allowance'          , wide: 2, typ:'decimal'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
//     dontLoadAll         :boolean= true
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Ptn', template:ItemDiv})
export class Ptn extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PtnService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ_id        : {lab:'type'               , wide: 6, cod:'ptn'    , copyCheck:true}

        , tier          : {lab:'tier number'        , wide: 6, typ:'integer', copyCheck:true
                                                                            , newline:true}
        , tier_name     : {lab:'tier name'          , wide: 6               , copyCheck:true}

        , descr         : {opt:'description'        , wide:12               , newline:true}

        , comm_pct      : {opt:'commission (%)'     , wide: 6, typ:'percent', newline:true}
        , annual_allowance
                        : {opt:'annual allowance'   , wide: 6, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
