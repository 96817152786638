//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , CodLngService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'CodLngLst', template:ListDiv})
export class CodLngLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:CodLngService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { grp_name      : {lab:'type'               , wide: 2}
        , lng_name      : {lab:'language'           , wide: 2}
        , cod_name      : {lab:'default'            , wide: 2}
        , name          : {lab:'value'              , wide: 2}
        , descr         : {lab:'description'        , wide: 5}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return this.ctx
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'CodLng', template:ItemDiv})
export class CodLng extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:CodLngService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','cod_id']
        , grp_name      : {rdo:'type'               , wide: 6}
        , lng_id        : {lab:'language'           , wide: 6, cod:'lng', copyCheck:true}

        , cod_name      : {rdo:'default'            , wide: 6}
        , name          : {lab:'value'              , wide: 6}

        , descr         : {opt:'description'        , wide:12}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
