//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, PrdService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PrdDtl', template:ItemDiv})
export class PrdDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:PrdService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , number        : {lab:'number'             , wide: 2               , copyCheck:true}
        , matchcode     : {lab:'matchcode (english)', wide: 4               , copyCheck:true}
        , typ_id        : {lab:'type'               , wide: 3, cod:'artXfam', copyCheck:true}
        , mne_id        : {lab:'units'              , wide: 3, cod:'mne'}


        , txt           : {opt:'text (english)'     , wide:12, typ:'textarea', rows:10, newline:true}


        , brn_id        : {lab:'brand'              , wide: 3, cod:'brn'    , newline:true}
        , dsg_id        : {opt:'designer'           , wide: 3, cod:'knt_sup'}
        , vnd_id        : {opt:'vendor'             , wide: 3, cod:'vnd'    }
        , coo_id        : {opt:'country of origin'  , wide: 3, cod:'lnd'    }


        , sup_id        : {lab:'supplier'           , wide: 3, cod:'knt_sup', newline:true}
        , on_behalf     : {opt:'on behalf'          , wide: 1, typ:'boolean'}
        , purch_pct     : {opt:'purchase %'         , wide: 2, typ:'percent'}
        , prd_uplift    : {opt:'cog uplift (%)'     , wide: 3, typ:'percent'}
        , brutto        : {opt:'base brutto'        , wide: 3, typ:'decimal'}


        , wrg_id        : {lab:'product group'      , wide: 3, cod:'wrg'    , newline:true}
        , pri_cls_id_ws : {lab:'wholesale class'    , wide: 3, cod:'pri_cls_ws'}
        , pri_cls_id_rt : {lab:'retail class'       , wide: 3, cod:'pri_cls_rt'}
        , shp_cls_id    : {lab:'shipping class'     , wide: 3, cod:'shp_cls'}


        , lnd_id_tax    : {opt:'country (tax)'      , wide: 3, cod:'lnd'    , newline:true}
        , txy_id        : {opt:'taxonomy'           , wide: 3, cod:'txy'    }
        , art_tax_id    : {lab:'sku tax group'      , wide: 3, cod:'art_tax'}
        , dty_id        : {lab:'duty (hs code)'     , wide: 3, cod:'dty'    }

        , stx_us_id     : {opt:'US sales tax cat.'  , wide: 3, cod:'stx_us' , newline:true}


        , stt_id        : {opt:'status'             , wide: 3, cod:'prd_stt', newline:true}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.formSubmit         (this.onSubmitP     )
        if (this.ctx.prd_id) {
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )

        return this.readP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        if (this.item.id) {
            this.setCache(this.item)
            this.ctx.id         =
            this.ctx.prd_id     =
            this.dft.prd_id     = this.item.id
            this.service.showValidTabs()
            this.service.setHeader(this.item)
            this.showButton('copy'  )
            this.showButton('delete')
        }
    }
    //--------------------------------------------------------------------------
    onCopyP() {
        this.ctx.id             =
        this.ctx.prd_id         =
        this.dft.prd_id         = 0
        return this.setItemValuesP({ id: 0 })
        .then(() => {
            this.service.initTabs(0,'dtl')
            this.service.setHeader('new...')
            this.hideButton('copy'  )
            this.hideButton('delete')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        return this.onBackP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
