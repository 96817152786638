//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , RbgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RbgLst', template:ListDiv})
export class RbgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:RbgService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide:10}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Rbg', template:ItemDiv})
export class Rbg extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:RbgService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide:12               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide:12, typ:'integer'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
