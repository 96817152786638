//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , ArtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtTktLst', template:ListDiv})
export class ArtTktLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { event         : {lab:'event'              , wide: 4}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        , number        : {lab:'sku'                , wide: 2}
        , matchcode     : {lab:'matchcode'          , wide: 6}
        , typ_name      : {lab:'type'               , wide: 2}
        , grouping      : {opt:'grouping'           , wide: 1}
        , tranche       : {opt:'batch'              , wide: 1, typ:'integer'}
        , restricted    : {lab:'restricted'         , wide: 1, typ:'flag'   }
        , waitlist      : {lab:'waitlist'           , wide: 1, typ:'flag'   }
        , extra_reqd    : {lab:'add camp'           , wide: 1, typ:'flag'   }
        , max_qty       : {opt:'max. qty'           , wide: 2, typ:'integer'}
        , sold_qty      : {opt:'sold qty'           , wide: 2, typ:'integer'}
        , max_cart_qty  : {opt:'max. cart'          , wide: 2, typ:'integer'}
        , availdate     : {lab:'available from'     , wide: 2, typ:'date'   }
//         , availto       : {lab:'available until'    , wide: 2, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'art_tkt_load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'ArtTkt', template:ItemDiv})
export class ArtTkt extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , evt_id        : {lab:'event'              , wide: 6, cod:'evt_all', copyCheck:true}

        , sort_no       : {lab:'sort'               , wide: 2, typ:'integer', newline:true}
        , number        : {lab:'sku'                , wide: 4               , copyCheck:true}
        , typ_id        : {opt:'type'               , wide: 6, cod:'art_tkt'}

        , grouping      : {opt:'grouping'           , wide: 3               , newline:true}
        , tranche       : {opt:'batch'              , wide: 3, typ:'integer'}
        , restricted    : {lab:'restricted'         , wide: 2, typ:'boolean'}
        , waitlist      : {lab:'waitlist'           , wide: 2, typ:'boolean'}
        , extra_reqd    : {lab:'add camp'           , wide: 2, typ:'boolean'}

        , max_qty       : {opt:'max. qty'           , wide: 3, typ:'integer', newline:true}
        , sold_qty      : {opt:'sold qty'           , wide: 3, typ:'integer'}
        , max_cart_qty  : {opt:'max. per cart'      , wide: 3, typ:'integer'}
        , availdate     : {lab:'available from'     , wide: 3, typ:'date'   }
//         , availto       : {lab:'available until'    , wide: 2, typ:'date'   }
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'art_tkt_read'
    onSave_task                 = 'art_tkt_save'
    onDelete_task               = 'art_tkt_dele'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
