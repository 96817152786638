//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , GrpTemplate           , TabbedComp
        , GrpService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'GrpLst', template:ListDiv})
export class GrpLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:GrpService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 5}
        , descr         : {lab:'description'        , wide:10}
        , cat_paths     : {lab:'categories'         , wide:20, typ:'textarea'}
//         , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onNewCompName               = 'Grp'
    onNewContext() {
        return { }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Grp'
    onUpdateContext(idx:number) {
        return { grp_id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Grp', template:GrpTemplate})
export class Grp extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:GrpService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.grp_id         =
        this.dft.grp_id         = this.ctx.grp_id// || this.dft.grp_id
        this.service.setHeader(null)
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
