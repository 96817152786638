//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , PayService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PayLst', template:ListDiv})
export class PayLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PayService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'type'               , wide: 2}
        , name          : {lab:'name'               , wide: 8}
        , schedule_name : {lab:'schedule'           , wide: 2}
        , due_days      : {lab:'due days'           , wide: 2, typ:'integer'}
        , dsc_days      : {lab:'disc days'          , wide: 2, typ:'integer'}
        , dsc_pct       : {lab:'disc pct'           , wide: 2, typ:'percent'}
        , acc_konto     : {lab:'acct'               , wide: 2}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Pay', template:ItemDiv})
export class Pay extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:PayService ) { super() }
    modal_size                  = 'small'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ           : {lab:'type'               , wide: 6               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 6, typ:'integer'}

        , name          : {lab:'name'               , wide:12}

        , schedule_id   : {lab:'schedule'           , wide: 6, cod:'pay_schedule'}
        , due_days      : {opt:'due days'           , wide: 6, typ:'integer'}

        , dsc_days      : {opt:'discount days'      , wide: 6, typ:'integer'}
        , dsc_pct       : {opt:'discount percent'   , wide: 6, typ:'percent'}

        , acc_konto     : {opt:'acct account'       , wide: 6}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
