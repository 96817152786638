//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , SupTemplate           , TabbedComp
        , KntService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'KntSupLst', template:ListDiv})
export class KntSupLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , email         : {lab:'email'              , wide: 6}
        , adr_city      : {lab:'city'               , wide: 6}
        , adr_postcode  : {lab:'postcode'           , wide: 2}
        , adr_lnd_name  : {lab:'country'            , wide: 4}
        , adr_email     : {lab:'addr. email'        , wide: 6}
        , adr_tel_num   : {lab:'phone'              , wide: 4}
        , mid_code      : {lab:'man. id'            , wide: 4}
//         , ord_next_date : {lab:'next order'         , wide: 2, typ:'date'}
//         , cur_typ       : {lab:'cur'                , wide: 1}
        , number        : {lab:'number'             , wide: 1}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft.typ            = 'sup'
        this.dft.subtyp         = ''
        this.dft.vendor         = undefined

        this.makeButton         (this.onOrderP      ,'create purchase order')

        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.buttonPrint        (this.onPrintP      )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onNewCompName               = 'KntSup'
//     onNewP() {
//         let ctx                 = this.onNewContext()
//          return this.callComponent('KntSup', ctx)
//         .then((res:any) => this.onNewDoneP(res) )
//     }
    onNewContext() {
        return  { id            : 0
                , typ           : this.dft.typ
                , subtyp        : this.dft.subtyp
                , vendor        : this.dft.vendor
                }
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'KntSup'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('KntSup', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        let massage             = (s:any) => {
            if (is.string(s)) {
                return s.replace(/[\n\t]/g,', ')
            } else {
                return s
            }
        }
        if (this.list.length < 1) {
            return REJECT('nothing to print')
        }
        let keys        :any    =
            { name              : 'name'
            , email             : 'account email'
            , adr_street        : 'street'
            , adr_city          : 'city'
            , adr_postcode      : 'postcode'
            , adr_lnd_name      : 'country'
            , adr_email         : 'address email'
            , adr_tel_num       : 'phone'
            , grp_name          : 'group'
            , cur_typ           : 'currency'
            , wsale_yn          : 'wholesale'
            , monthly           : 'monthly'
            }

        let data                = Object.keys(keys).map((k:string) => keys[k])
        data                    = [ data.join('\t') ]
        this.list.forEach((item:any) => {
            let values          = []
            for (let key in keys) {
                values.push(massage(item[key]))
            }
            data.push(values.join('\t'))
        })
        this.saveFile(data.join('\n'), 'account details.csv', 'text/csv')
        this.messageDone('saved as "account details.csv"')
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onOrderP(key:string, values:any) {
        let knt_ids
        return this.getSelectedIdsP()
        .then((ids) => {
            knt_ids             = ids
            return this.callComponent('ParamsModal',
                { header        : 'order creation parameters...'
                , itemDefn      :
                    { rsr_id    : {lab:'warehouse'        , cod:'rsr_with_rcv'}
                    , lead_time : {lab:'lead time (weeks)', typ:'integer' }
                    }
                , values        :
                    { rsr_id    : this.ctx.rsr_id
                    , lead_time : 1
                    }
                })
        })
        .then((res:any) => {
            if (!res)           { return RESOLVE() }
            return this.busyServiceEmitP('ior_create',
                { ...res
                , knt_ids       : knt_ids
                })
        })
        .then(() => {
            this.messageDone('purchase order creation scheduled')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'KntSup', template:SupTemplate})
export class KntSup extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.knt_id         =
        this.dft.knt_id         = this.ctx.id
        this.dft.typ            = this.ctx.typ
        this.dft.subtyp         = this.ctx.subtyp
        this.dft.vendor         = this.ctx.vendor
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
