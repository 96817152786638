//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { PstListDiv            , PstList
        , PstModalDiv           , PstModal
        , StkService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'StkShlLst', template:PstListDiv})
export class StkShlLst extends PstList {
    //--------------------------------------------------------------------------
    constructor( public service:StkService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    selectorDefaults    :any    = { selector: '' }
    parseTask           :string = 'parse_arts_to_shelf'
    parseBase           :any    = {}
    recalcPst                   = false
    price_reqd                  = false
    allow_zero_qtys             = true

    mergeKey                    = 'art_id'
    xrefKeys                    = 'ean_number,number,code,matchcode'.split(/\W+/g)
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { selector      : {lab:'article number or barcode'
                                                    , wide: 12}
        }
    listDefn            :any    =
        { shelves       : {lab:'shelves'            , wide: 5, typ:'textarea'}
        , number        : {lab:'number'             , wide: 5}
        , matchcode     : {lab:'matchcode'          , wide:12}
        , code          : {lab:'code'               , wide: 3}
        , ean_number    : {lab:'ean number'         , wide: 3}
        , ext_number    : {lab:'external sku'       , wide: 3}
        , count_date    : {lab:'last counted'       , wide: 3, typ:'date'}
        , onhand        : {lab:'onhand'             , wide: 1, typ:'quantity'}
        , qty           : {lab:'counted'            , wide: 1, typ:'quantity'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'shl_load'
    onRead_task                 = 'DEFAULTS'        // read selectorDefaults
    onSave_task                 = 'shl_save'
    onDelete_task               = 'shl_dele'
    //--------------------------------------------------------------------------
    onInitP() {
//TEST        this.dft                = this.ctx
        this.dft                =
            { rsr_id            : this.ctx.rsr_id
            , shelf             : this.ctx.shelf
            , vorgang           : this.ctx.vorgang
            , channel           : this.ctx.channel
            , vendor            : this.ctx.vendor
            , workflow          : this.ctx.workflow
            , status            : this.ctx.status
            }
        this.parseBase          =
            { rsr_id            : this.ctx.rsr_id
            , shelf             : this.ctx.shelf
            }
        this.defineItem         (this.itemDefn      )
        this.defineList         (this.listDefn, true)
        this.setHeader          (this.ctx.shelf     )
        this.buttonBack         (this.onBackP       )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonRemSel       (this.onDeleteP     )
        this.makeButton         (this.onCommitP     , 'commit')
        this.formSubmit         (this.onSubmitP     )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateListP )

        this.item               = Object.assign({}, this.selectorDefaults)

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    loadResult(list:any) {
        list.forEach((item:any) => {
            item.DIRTY          = true
            item.qty            = undefined
        })
        return list
    }
    loadDoneP() {
        return this.setDefaultsP()
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    onCommitP(key:string, values:any) {
        let list                = this.list
        if (list && list.some((item:any) => item.qty === undefined)) {
            return REJECT('update all counts before commit')
        }
        return this.busyServiceEmitP(this.onSave_task, { skus:list })
        .then(() => this.onBackP(false) )
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
// this.log('setColour:', idx)
        let colour              = (item.qty === undefined)  ? 'none'
                                : (item.qty < item.onhand)  ? 'red'
                                : (item.qty > item.onhand)  ? 'red'
                                                            : 'green'
        this.set_cell_bg(idx, 'qty', colour)
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'StkShl', template:PstModalDiv})
export class StkShl extends PstModal {
    //--------------------------------------------------------------------------
    constructor( public service:StkService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['idx','art_id']
        , shelves       : {rdo:'shelves'            , wide:12}
        , number        : {rdo:'number'             , wide:12}
        , onhand        : {rdo:'current onhand'     , wide:12, typ:'quantity'}
        , qty           : {opt:'stock count'        , wide:12, typ:'quantity', chg:this.on_qty}
        , shelf         : {opt:'shelf'              , wide: 9                , rtn:this.onKeyP}
        , shelf_btn     : {lab:'add/remove'         , wide: 3                , btn:this.onKeyP}
        , weight        : {opt:'weight'             , wide: 9, typ:'quantity', rtn:this.onKeyP}
        , weight_btn    : {lab:'update'             , wide: 3                , btn:this.onKeyP}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        this.setHeader          ('details'          )
        this.buttonPrev         (this.onPrevP       )
        this.buttonNext         (this.onNextP       )
        this.buttonCancel       (this.onCancelP     )
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        this.setSource(this.cache[this.name + 'Lst'])
        return this.setItemFromSourceP(this.ctx.idx)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {
        values.shelf            = ''
    }
    //--------------------------------------------------------------------------
    on_qty(key:string, value:any) {
        this.item.qty           = value
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
