//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , WtlService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'WtlLst', template:ListDiv})
export class WtlLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:WtlService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
        , art_number    : {lab:'number'             , wide: 6}
        , matchcode     : {lab:'matchcode'          , wide:12}
        , qty           : {lab:'qty'                , wide: 2, typ:'quantity', tot:true}
        , number        : {lab:'order no.'          , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 6}
        , knt_name      : {lab:'customer'           , wide:12}
        , stt_name      : {lab:'status'             , wide: 4}
        , events_flag   : {lab:'evn'                , wide: 2}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft                =
            { vorgang           : this.ctx.vorgang
            , channel           : this.ctx.channel
            , vendor            : this.ctx.vendor
            , workflow          : this.ctx.workflow
            , status            : this.ctx.status
            }
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.eventButtons       (this.ctx.events    )
        this.makeButton         (this.onEventP, 'grt', 'grant' )
        this.makeButton         (this.onEventP, 'hld', 'hold'  )
        this.makeButton         (this.onEventP, 'cnc', 'cancel')
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return { art_number:'*', qty:0, stt_name:'wtl' }
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            return this.busyServiceEmitP('event',
                { event         : key
                , vrg_ids       : ids
                })
        })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Vrg'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Vrg', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id, canEdit:true }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
