//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, EntService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectEnt', template:SelectDiv})
export class SelectEnt extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:EntService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { value         : {opt:'group name'         , wide:12}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        if (this.ctx.multi)     { this.setHeader('select groups') }
        else                    { this.setHeader('select group' ) }
        this.onInitSelect()

        return this.onSearchP('search', this.item)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
