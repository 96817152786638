//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , DtyCodService                             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'DtyCodLst', template:ListDiv})
export class DtyCodLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:DtyCodService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { typ           : {lab:'name'               , wide:20}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'DtyCod', template:ItemDiv})
export class DtyCod extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:DtyCodService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , typ           : {lab:'name'               , wide:12, copyCheck:true}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
