//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , TktService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'TktSum', template:ListDiv})
export class TktSum extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:TktService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { startdate     : {lab:'date'               , wide: 2, typ:'dates'  }
        , available     : {lab:'avail'              , wide: 1, typ:'flag'   }
        , number        : {lab:'article'            , wide: 2}
        , matchcode     : {lab:'matchcode'          , wide:10}
        , brutto        : {lab:'price'              , wide: 1, typ:'decimal'}
        , max_qty       : {lab:'limit'              , wide: 1, typ:'integer'}
        , sold_qty      : {lab:'ordered'            , wide: 1, typ:'intnone', tot:true}
        , checkout      : {lab:'checkout'           , wide: 1, typ:'intnone', tot:true}
        , issued        : {lab:'issued'             , wide: 1, typ:'intnone', tot:true}
        , unused        : {lab:'unused'             , wide: 1, typ:'intnone', tot:true}
        , cancelled     : {lab:'cancelled'          , wide: 1, typ:'intnone', tot:true}
        , vouchered     : {lab:'vouchered'          , wide: 1, typ:'intnone', tot:true}
        , used          : {lab:'used'               , wide: 1, typ:'intnone', tot:true}
        , inv_netto     : {lab:'invoiced netto'     , wide: 1, typ:'decimal', tot:true}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'summary'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        let since               = this.ctx.since || 'week'
        return Object.assign({ date0:this.getDateSince(since) }, this.ctx)
    }
    //--------------------------------------------------------------------------
    setItemColour(item:any, idx:number) {
        this.set_cell_bg(idx, 'sold_qty', item.sold_qty >= item.max_qty ? 'green'  : 'none')
        this.set_cell_bg(idx, 'reserved', item.reserved                 ? 'orange' : 'none')
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
